<template>
  <area-container>
    <area-nav :links="links"></area-nav>
    <area-content>
      <router-view></router-view>
    </area-content>
  </area-container>
</template>

<script>
export default {
  name: "AdminLayout",
  data() {
    return {
      links: [
        { route: "global.Publishing", name: this.$tk("GlobalLayout.Publishing") },
        { route: "global.Caching", name: this.$tk("GlobalLayout.Caching") },
        { route: "global.Log", name: this.$tk("GlobalLayout.Log") }
      ]
    }
  }
}
</script>
